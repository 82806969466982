import { Col, Divider, Drawer, Row, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { calculateTimeDifference } from "../../helpers/utils";
import _ from "lodash";
const { Text, Title } = Typography;

const DetailTransaction = ({
  visible,
  onClose,
  ticketDetails,
  airports,
  trasaction,
}) => {
  const [vmbBookingFlights, setVmbBookingFlights] = useState({
    departFlight: null,
    returnFlight: null,
  });
  const [isOneWay, setIsOneWay] = useState(false);
  const [vmbTickets, setVmbTickets] = useState({
    depart: null,
    return: null,
  });
  const [ticketSummary, setTicketSummary] = useState({
    totalAdt: 0,
    totalChd: 0,
    totalInf: 0,
    totalBaggages: 0,
    totalDiscount: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    if (trasaction) {
      const IsOneWay =
        trasaction &&
        trasaction.vmbBookings.length === 1 &&
        trasaction.vmbBookings[0].route.split("|").length === 1;
      setIsOneWay(IsOneWay);
      if (IsOneWay) {
        const departFlight = _.get(
          trasaction,
          "vmbBookings[0].vmbBookingFlights[0]"
        );
        setVmbBookingFlights({
          ...vmbBookingFlights,
          departFlight: departFlight,
        });
        setTicketSummary({
          ...ticketSummary,
          totalAdt: _.sum(),
        });

        setVmbTickets({
          depart:
            _.get(trasaction, "vmbTickets", []).length > 0
              ? _.get(trasaction, "vmbTickets", [])
              : null,
          return: null,
        });
      } else {
        //NOTE: Chuyến bay khác hãng có 2 booking
        if (trasaction?.vmbBookings.length === 2) {
          const departFlight = _.get(
            trasaction,
            "vmbBookings[0].vmbBookingFlights[0]"
          );
          const returnFlight = _.get(
            trasaction,
            "vmbBookings[1].vmbBookingFlights[0]"
          );
          setVmbBookingFlights({
            ...vmbBookingFlights,
            departFlight: departFlight,
            returnFlight: returnFlight,
          });
          const routeDepart = trasaction.vmbBookings[0].route;
          const routeReturn = trasaction.vmbBookings[1].route;
          setVmbBookingFlights({
            ...vmbBookingFlights,
            departFlight: departFlight,
            returnFlight: returnFlight,
          });
          setVmbTickets({
            depart: _.filter(
              trasaction.vmbTickets,
              (ticket) =>
                `${ticket.startPoint}${ticket.endPoint}` === routeDepart
            ),
            return: _.filter(
              trasaction.vmbTickets,
              (ticket) =>
                `${ticket.startPoint}${ticket.endPoint}` === routeReturn
            ),
          });
          //NOTE: Chuyến bay cùng hãng có 1 booking
        } else {
          const departFlight = _.get(
            trasaction,
            "vmbBookings[0].vmbBookingFlights[0]"
          );
          const returnFlight = _.get(
            trasaction,
            "vmbBookings[0].vmbBookingFlights[1]"
          );
          const routes = trasaction.vmbBookings[0].route.split("|");
          const routeDepart = routes[0];
          const routeReturn = routes[1];
          setVmbBookingFlights({
            ...vmbBookingFlights,
            departFlight: departFlight,
            returnFlight: returnFlight,
          });
          setVmbTickets({
            depart: _.filter(
              trasaction.vmbTickets,
              (ticket) =>
                `${ticket.startPoint}${ticket.endPoint}` === routeDepart
            ),
            return: _.filter(
              trasaction.vmbTickets,
              (ticket) =>
                `${ticket.startPoint}${ticket.endPoint}` === routeReturn
            ),
          });
        }
      }
    }
  }, [trasaction]);
  return (
    <Drawer placement="bottom" onClose={onClose} open={visible} height={700}>
      <Title level={4} className="px-4">
        {
          (airports || []).find(
            (item) => item.code === ticketDetails?.startPoint || ""
          )?.city_name_vi
        }{" "}
        -{" "}
        {
          (airports || []).find(
            (item) => item.code === ticketDetails?.endPoint || ""
          )?.city_name_vi
        }{" "}
      </Title>
      <div className="flex flex-col gap-2 px-4">
        {!isOneWay && vmbBookingFlights.departFlight && (
          <div className="text-[12px] font-bold">Chiều đi</div>
        )}
        {vmbBookingFlights.departFlight && (
          <div
            className="p-2 rounded-[8px] flex flex-col gap-2"
            style={{
              border: "1px solid #EBEBF0",
            }}
          >
            <div className="flex gap-4 items-center">
              <img
                src={`https://abtrip.vn/images/airlines/${(
                  vmbBookingFlights.departFlight.airline || ""
                ).toLowerCase()}.gif`}
                alt="logo"
                style={{ width: "auto", height: 30 }}
              />
              <div className="flex flex-col justify-between">
                {" "}
                <Text className="font-bold text-[14px]" strong>
                  {vmbBookingFlights.departFlight.airlineName} -{" "}
                  {vmbBookingFlights.departFlight?.fareClass}
                </Text>
                <Text className="text-[14px] text-[#808089]">
                  {vmbBookingFlights.departFlight?.flightNumber}
                </Text>
              </div>
            </div>
            <hr
              style={{
                border: "1px solid #EBEBF0",
              }}
            />
            <Row gutter={16} align={"middle"}>
              <Col span={8}>
                <h4 className="font-bold text-sm">
                  {vmbBookingFlights?.departFlight?.startPoint}
                </h4>
                <p className="text-xs">
                  {dayjs(vmbBookingFlights?.departFlight?.startDate).format(
                    "HH:mm DD/MM/YYYY"
                  )}
                </p>
              </Col>
              <Col span={8} className="justify-center text-center">
                <p className="text-xs text-[#808089]">
                  {calculateTimeDifference(
                    vmbBookingFlights?.departFlight?.startDate,
                    vmbBookingFlights?.departFlight?.endDate
                  )}
                </p>
                <img src="/images/flight-line.svg" alt="" />
              </Col>
              <Col span={8}>
                <h4 className="font-bold text-sm">
                  {vmbBookingFlights?.departFlight?.endPoint}
                </h4>
                <p className="text-xs">
                  {dayjs(vmbBookingFlights?.departFlight?.endDate).format(
                    "HH:mm DD/MM/YYYY"
                  )}
                </p>
              </Col>
            </Row>
          </div>
        )}
        {!_.isNil(vmbTickets.depart) &&
          vmbTickets.depart.length > 0 &&
          _.some(vmbTickets.depart, (ticket) => ticket.status === 1) && (
            <div>
              <div className="mb-4 font-bold">Thông tin vé</div>
              {vmbTickets.depart.map((ticket) => (
                <div className="flex flex-col gap-2">
                  <div>
                    <Row justify="space-between">
                      <Text>Hành khách</Text>
                      <span className="font-bold text-[#01AD53]">
                        {ticket.passengerName}
                      </span>
                    </Row>{" "}
                    <Row justify="space-between">
                      <Text>Hạng vé</Text>
                      <span className="font-bold text-[#01AD53]">
                        {ticket.groupClass}
                      </span>
                    </Row>
                    <Row justify="space-between">
                      <Text>Mã booking | Mã vé</Text>
                      <span className="font-bold text-[#01AD53]">
                        {ticket.bookingCode} | {ticket?.ticketNumber}
                      </span>
                    </Row>
                    <Divider
                      variant="dashed"
                      style={{ borderColor: "#01AD53" }}
                      dashed
                      className="my-2 py-0"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        {!isOneWay && vmbBookingFlights.returnFlight && (
          <>
            <div className="text-[12px] font-bold">Chiều về</div>
            <div
              className="p-2 rounded-[8px] flex flex-col gap-2"
              style={{
                border: "1px solid #EBEBF0",
              }}
            >
              <div className="flex gap-4 items-center">
                <img
                  src={`https://abtrip.vn/images/airlines/${(
                    vmbBookingFlights?.returnFlight.airline || ""
                  ).toLowerCase()}.gif`}
                  alt="logo"
                  style={{ width: "auto", height: 30 }}
                />
                <div className="flex flex-col justify-between">
                  {" "}
                  <Text className="font-bold text-[14px]" strong>
                    {vmbBookingFlights?.returnFlight?.airlineName} -{" "}
                    {vmbBookingFlights?.returnFlight?.fareClass}
                  </Text>
                  <Text className="text-[14px] text-[#808089]">
                    {vmbBookingFlights?.returnFlight?.flightNumber}
                  </Text>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #EBEBF0",
                }}
              />
              <Row gutter={16} align={"middle"}>
                <Col span={8}>
                  <h4 className="font-bold text-sm">
                    {vmbBookingFlights?.returnFlight?.startPoint}
                  </h4>
                  <p className="text-xs">
                    {dayjs(vmbBookingFlights?.returnFlight?.startDate).format(
                      "HH:mm DD/MM/YYYY"
                    )}
                  </p>
                </Col>
                <Col span={8} className="justify-center text-center">
                  <p className="text-xs text-[#808089]">
                    {calculateTimeDifference(
                      vmbBookingFlights?.returnFlight?.startDate,
                      vmbBookingFlights?.returnFlight?.endDate
                    )}
                  </p>
                  <img src="/images/flight-line.svg" alt="" />
                </Col>
                <Col span={8}>
                  <h4 className="font-bold text-sm">
                    {vmbBookingFlights?.returnFlight?.endPoint}
                  </h4>
                  <p className="text-xs">
                    {dayjs(vmbBookingFlights?.returnFlight?.endDate).format(
                      "HH:mm DD/MM/YYYY"
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </>
        )}
        {!_.isNil(vmbTickets.return) &&
          vmbTickets.return.length > 0 &&
          _.some(vmbTickets.return, (ticket) => ticket.status === 1) && (
            <div>
              <div className="mb-2 font-bold">Thông tin vé</div>
              {vmbTickets.return.map((ticket) => (
                <div className="flex flex-col gap-2">
                  <div>
                    <Row justify="space-between">
                      <Text>Hành khách</Text>
                      <span className="font-bold text-[#01AD53]">
                        {ticket.passengerName}
                      </span>
                    </Row>{" "}
                    <Row justify="space-between">
                      <Text>Hạng vé</Text>
                      <span className="font-bold text-[#01AD53]">
                        {ticket.groupClass}
                      </span>
                    </Row>
                    <Row justify="space-between">
                      <Text>Mã booking | Mã vé</Text>
                      <span className="font-bold text-[#01AD53]">
                        {ticket.bookingCode} | {ticket?.ticketNumber}
                      </span>
                    </Row>
                    <Divider
                      variant="dashed"
                      style={{ borderColor: "#01AD53" }}
                      dashed
                      className="my-2 py-0"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

        {!_.some(vmbTickets.depart, (ticket) => ticket.status === 1) &&
          !_.some(vmbTickets.return, (ticket) => ticket.status === 1) && (
            <div>Không có dữ liệu xuất vé</div>
          )}
      </div>
    </Drawer>
  );
};

export default DetailTransaction;
