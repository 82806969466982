import {
  LeftCircleOutlined,
  SwapOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  DatePicker,
  Row,
  notification,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { formatNumberWithDots, getStatusDiv } from "../../helpers/utils";
import locale from "antd/es/date-picker/locale/vi_VN";
import $axios from "../../services/axios";
import DetailTransaction from "../Common/DetailTransaction";
import moment from "moment";
import _ from "lodash";
import dayjs from "dayjs";
const History = ({ airports, onChangeStep, setLoading }) => {
  //NOTE: 1 chiều vmbBookings.length === 1 || route.split("|").length === 1
  //NOTE: 2 chiều là TH còn lại
  //NOTE: 2 chiều thì vmbBookings = 1 hoặc vmbBookings = 2
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [detailTicket, setDetailTicket] = useState({});
  const [transaction, setTransaction] = useState(null);
  const [listHistory, setListHistory] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rangePresets = [
    {
      label: "7 ngày qua",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "14 ngày qua",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "30 ngày qua",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "90 ngày qua",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  useEffect(() => {
    getListHistory(currentPage, _.get(rangePresets, "[0].value", []));
  }, []);

  const getListHistory = async (page, dateRange) => {
    const startTime = dayjs(dateRange[0]).format("DD/MM/YYYY") + " 00:00:00";
    const endTime = dayjs(dateRange[1]).format("DD/MM/YYYY") + " 00:00:00";
    setLoading(true);
    try {
      const res = await $axios.$get(
        `/transactions/list?startDate=${startTime}&endDate=${endTime}&page=${currentPage}&pageSize=10`
      );
      setListHistory(res.data.data.data);
      setTotal(res.data.data.totalRecordCount);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      await getListHistory(currentPage, values.dateRange);
    } catch (error) {
      notification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };

  const checkExpiredBooking = (expiryDate) => {
    if (!expiryDate) return true;
    const isExpired = moment().isAfter(expiryDate);
    return isExpired;
  };

  const verifyFlight = async (item) => {
    const vmbFareData = _.get(item, "vmbBookings[0].vmbFareData", []);
    if (!vmbFareData.length) return false;
    setLoading(true);
    try {
      const totalNetPriceOrigin = _.sum(
        _.map(vmbFareData, (fare) => fare.totalNetPrice)
      );
      const body = {
        ListFareData: _.map(vmbFareData, (f) => {
          return {
            Session: f.session,
            FareDataId: f.fareDataId,
            ListFlight: JSON.parse(f.listFlight).map((f2) => {
              return {
                FlightValue: f2.FlightValue,
              };
            }),
          };
        }),
      };
      const res = await $axios.$post("/flights/verifyflight", body);
      if (res.data.data.ErrorCode === "000") {
        const totalNetPriceCompare =
          _.get(res.data.data, "ListFareStatus[0].FareData.TotalNetPrice", 0) +
          _.get(res.data.data, "ListFareStatus[1].FareData.TotalNetPrice", 0);
        return totalNetPriceOrigin === totalNetPriceCompare;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const rePayment = async (item) => {
    const canBooking = verifyFlight(item);
    if (!canBooking) {
      notification.error({
        status: "error",
        description:
          "Chuyến bay đã có sự thay đổi về giá. Vui lòng tìm kiếm lại chuyến bay để cập nhật!",
      });
    }
    // window.location.href = item.paymentUrl;
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full 0  bg-white shadow-lg rounded-lg">
        <img
          className="w-full h-[260px] object-cover bg-step1"
          src="/images/earth.svg"
          alt=""
        />
        <div className="w-[92%] mx-auto absolute top-[7%] left-1/2 transform -translate-x-1/2 -translate-y-4/5 pb-[40px] flex flex-col">
          <div className="flex justify-center items-center">
            <div className="flex flex-col gap-2 items-center w-full">
              <div className="w-full flex items-center justify-between">
                <LeftCircleOutlined
                  onClick={() => onChangeStep(1)}
                  className="text-white text-[32px]"
                />

                <img
                  src={"/images/flight-icon.svg"}
                  alt=""
                  className="w-[57px]"
                  onClick={() => onChangeStep(1)}
                />
                <div className="w-8 h-8"></div>
              </div>
              <h2 className="text-white text-[16px] font-semibold">
                Lịch sử đặt vé
              </h2>
              <div className="w-full px-3 py-3 rounded-[20px] bg-white mt-[20px]">
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="horizontal"
                  initialValues={{
                    dateRange: rangePresets[0].value, // Giá trị mặc định cho `dateRange`
                  }}
                  className="flex items-start gap-2 m-0"
                >
                  <Form.Item
                    name={"dateRange"}
                    rules={[
                      { required: true, message: "Vui lòng chọn thời gian!" },
                    ]}
                    className="m-0 flex-1"
                  >
                    <DatePicker.RangePicker
                      popupClassName="ant-picker-no-left"
                      classNames="history-picker"
                      presets={rangePresets}
                      format="DD/MM/YYYY"
                      className="h-10"
                      locale={locale}
                      suffixIcon={<img src={"/images/date.svg"} alt="" />}
                    />
                  </Form.Item>

                  <Form.Item className="m-0">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="bg-[#01AD53] h-10 font-bold text-white"
                    >
                      Tìm kiếm
                    </Button>
                  </Form.Item>
                </Form>
                {/* Hotline */}
              </div>

              <div className="w-full mt-4">
                <div className="text-center mt-8">
                  <span className="text-[#808080] font-normal">
                    Hotline hỗ trợ khách hàng{" "}
                  </span>
                  <a
                    href="tel:0876100800"
                    className="font-bold text-[#01AD53] text-[16px]"
                  >
                    0876100800
                  </a>
                </div>
                <Button
                  className="mt-3 rounded-[20px] bg-[#01ad53] text-white"
                  variant="filled"
                  color="#01AD53"
                  ghost
                >
                  <p className="font-bold text-white">Vé máy bay</p>
                </Button>
              </div>
              {listHistory.length === 0 && (
                <h2 className="w-full text-primary font-semibold text-[19px] text-center absolute top-[480px] left-1/2 transform -translate-x-1/2 p-4">
                  Không tìm thấy lịch sử đặt vé, vui lòng tìm kiếm lại
                </h2>
              )}
              {listHistory.map((item, index) => (
                <div
                  key={index}
                  className="mt-2 w-full p-4 bg-white rounded-[20px] mb-4 "
                  style={{
                    boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Row gutter={8} className="justify-between items-center">
                    <Col className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <div className="text-[12px] text-[#01AD53]">
                          {
                            (airports || []).find(
                              (air) => air.code === item?.startPoint || ""
                            )?.city_name_vi
                          }{" "}
                          ({item?.startPoint})
                        </div>

                        {item.vmbBookings.length === 1 &&
                        item.vmbBookings[0].route.split("|").length === 1 ? (
                          <SwapRightOutlined style={{ color: "#01AD53" }} />
                        ) : (
                          <SwapOutlined style={{ color: "#01AD53" }} />
                        )}
                        <div className="text-[12px] text-[#01AD53]">
                          {
                            (airports || []).find(
                              (air) => air.code === item?.endPoint || ""
                            )?.city_name_vi
                          }{" "}
                          ({item?.endPoint})
                        </div>
                      </div>
                      <div className="text-[#808089] text-[12px]">
                        Loại vé:{" "}
                        <span>
                          {item.vmbBookings.length === 1 &&
                          item.vmbBookings[0].route.split("|").length === 1
                            ? "Một chiều"
                            : "Khứ hồi"}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className="flex gap-2 items-center mb-2">
                        <div className="font-bold text-primary text-[12px] ">
                          {formatNumberWithDots(item?.amount)} VND
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-center text-center">
                        <span
                          className="text-primary text-[10px]"
                          onClick={() => {
                            setDetailTicket(item);
                            setDrawerVisible(true);
                            setTransaction(item);
                          }}
                        >
                          Chi tiết <img src="/images/2-down.svg" alt="" />
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-2" />
                  <Row className="justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <img
                        className="w-auto h-4"
                        src={`https://abtrip.vn/images/airlines/${item?.vmbBookings[0]?.vmbBookingFlights[0]?.airline.toLowerCase()}.gif`}
                        alt=""
                      />
                      <div>
                        <p className="text-[10px] text-[#808089] mt-1">
                          Mã đơn hàng: {item?.orderCode || ""}
                        </p>
                        <p className="text-[10px] text-[#808089] mt-1">
                          Thời gian:{" "}
                          {moment(item?.createdAt).format("HH:mm DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col gap-1 items-center">
                      {getStatusDiv(item?.bookingStatus)}
                      {item.paymentTransaction === "" &&
                        checkExpiredBooking(
                          item?.vmbBookings[0].expiryDate
                        ) && (
                          <div
                            onClick={() => rePayment()}
                            className={`text-white bg-red-500 rounded-[20px] px-2 py-1 w-fit text-[10px]`}
                          >
                            Thanh toán lại
                          </div>
                        )}

                      {/* {item.paymentTransaction !== "" && (
                        <div
                          onClick={() => rePayment(item)}
                          className={`text-white bg-red-500 rounded-[20px] px-2 py-1 w-fit text-[10px]`}
                        >
                          Thanh toán lại
                        </div>
                      )} */}
                    </div>
                  </Row>
                </div>
              ))}

              {total > 0 && (
                <Pagination
                  current={currentPage}
                  className="mt-2"
                  total={total}
                  onChange={(page) => {
                    setCurrentPage(page);
                    getListHistory(page);
                  }}
                  pageSize={10}
                  showSizeChanger={false}
                  showQuickJumper={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {drawerVisible && transaction && (
        <DetailTransaction
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          ticketDetails={detailTicket}
          trasaction={transaction}
          airports={airports}
        />
      )}
    </div>
  );
};

export default History;
