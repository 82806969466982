import React, { createContext } from "react";
import BgForm from "../../images/bg-form.png";
import IconFlight from "../../images/ic-flights.svg";
import IconNoti from "../../images/icon-noti.svg";
import IconArrrow from "../../images/icon-arrow.svg";
import IconFlights from "../../images/flights.svg";
import { Button, Modal } from "antd";
import moment from "moment";
import { calculateTimeDifference } from "../../helpers/utils";
import _ from "lodash";
const ReachableContext = createContext(null);

const Step5 = ({
  bookingInfo,
  chooseFlight,
  airportInfo,
  isPromotionOrSameDayTicket,
  airlineInfo,
  airports,
}) => {
  const baggage = _.sum([
    bookingInfo.totalListPrice.baggage,
    bookingInfo.totalListPrice.baggage_out,
  ]);
  const [modal, contextHolder] = Modal.useModal();
  const config = {
    title: "Thông báo!",
    maskClosable: false,
    closable: false,
    footer: [
      <div className="text-center w-full">
        <Button
          key="ok"
          type="primary"
          onClick={() => (window.location.href = "/vemaybay")}
          className="bg-[#01AD53] text-white"
        >
          Về trang tìm kiếm
        </Button>
      </div>,
    ],
    content: (
      <>
        <ReachableContext.Consumer>
          {() => (
            <div className="text-[14px] text-[#0D1634] font-medium">
              Đã hết hạn thanh toán. Vui lòng tìm kiếm lại chuyến bay để cập
              nhật!
            </div>
          )}
        </ReachableContext.Consumer>
        <br />
      </>
    ),
  };

  const handlePayment = async () => {
    if (!isPromotionOrSameDayTicket) {
      const expiryDate = bookingInfo.transaction.vmbBookings[0].expiryDate;
      const isExpired = moment().isAfter(expiryDate);
      if (isExpired) {
        await modal.confirm(config);
        return;
      }
    }
    window.location.href = bookingInfo.paymentUrl;
  };

  return (
    <div className="bg-[#F6F6F6] mb-4">
      <div className="bg-[#01ad53] h-[300px] relative">
        <section className="step-indicator">
          <div className="step step1 actived">
            <div
              className="step-icon"
              onClick={() => (window.location.href = "/vemaybay")}
            >
              1
            </div>
            <p>Tìm kiếm</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step2 actived">
            <div className="step-icon">2</div>
            <p>Thông tin</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step3 actived">
            <div className="step-icon">3</div>
            <p>Xác nhận</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step4 active">
            <div className="step-icon">4</div>
            <p>Thanh Toán</p>
          </div>
        </section>
        <img src={BgForm} alt="" className="w-full object-cover mx-auto" />
        <div className="flex w-full absolute top-[135px] left-1/2 transform -translate-x-1/2 justify-center gap-8">
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight[0].ListFlight[0].StartPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].StartPoint
                )?.city_name_vi
              }
            </div>
          </div>
          <div className="relative">
            <img src={IconFlight} alt="" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight[0].ListFlight[0].EndPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].EndPoint
                )?.city_name_vi
              }
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto absolute top-[200px]  left-1/2 transform -translate-x-1/2 pb-[120px] flex flex-col">
          <div
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            className="rounded-[20px] bg-white shadow-lg p-4 mb-[24px] text-[14px] text-[#27272A]"
          >
            <div>
              <div className="flex gap-3">
                <img
                  className="w-[60px] h-[30px]"
                  src={`https://abtrip.vn/images/airlines/${chooseFlight[0].ListFlight[0].Airline.toLowerCase()}.gif`}
                  alt=""
                />
                <div className="flex flex-col gap-1">
                  <div className="font-bold text-[#27272A]">
                    {
                      airlineInfo.find(
                        (air) => air.code === chooseFlight[0].Airline
                      ).name_en
                    }
                  </div>
                  <div className="text-[#808089]">
                    {chooseFlight[0].ListFlight[0].FlightNumber}
                  </div>
                </div>
              </div>
              <hr
                style={{ borderTopWidth: "0.5px", borderColor: "#ebebf0" }}
                className="my-3"
              />
              <div className="flex w-full justify-between items-center">
                <div className="flex flex-col">
                  <div className="font-bold">
                    {chooseFlight[0].ListFlight[0].StartPoint}
                  </div>
                  <div className="text-[12px]">
                    {moment(chooseFlight[0].ListFlight[0].StartDate).format(
                      "HH:mm"
                    )}
                  </div>
                  <div className="text-[12px]">
                    {moment(chooseFlight[0].ListFlight[0].StartDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </div>
                <div className="relative">
                  <div className="text-[12px] w-full text-center text-[#808089] absolute left-1/2 transform -translate-x-1/2 bottom-[12px]">
                    {calculateTimeDifference(
                      chooseFlight[0].ListFlight[0].StartDate,
                      chooseFlight[0].ListFlight[0].EndDate
                    )}
                  </div>
                  <img
                    src={IconFlights}
                    alt=""
                    className="absolute bottom-[-15px] left-1/2 transform -translate-x-1/2"
                  />
                  <img src={IconArrrow} alt="" />
                </div>
                <div className="flex flex-col">
                  <div className="font-bold">
                    {chooseFlight[0].ListFlight[0].EndPoint}
                  </div>
                  <div className="text-[12px]">
                    {moment(chooseFlight[0].ListFlight[0].EndDate).format(
                      "HH:mm"
                    )}
                  </div>
                  <div className="text-[12px]">
                    {moment(chooseFlight[0].ListFlight[0].EndDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </div>
              </div>
            </div>
            {chooseFlight[0].ListFlight[0].StopNum > 0 &&
              chooseFlight[0].ListFlight[0].ListSegment.map((s) => (
                <>
                  {s.StopTime > 0 && (
                    <div
                      key={s.Id}
                      style={{ border: "1px solid #DDDDE3" }}
                      className="flex gap-2 p-2 rounded-[4px] my-4 items-center"
                    >
                      <img src={IconNoti} alt="" />
                      <span>
                        Dừng để đổi chuyến bay tại{" "}
                        {
                          airports.find((air) => air.code === s.EndPoint)
                            ?.city_name_vi
                        }{" "}
                        ({s.StopTime}
                        )m
                      </span>
                    </div>
                  )}
                </>
              ))}
            {chooseFlight.length === 2 && (
              <>
                <div className="mt-[24px]">
                  <div className="flex gap-3">
                    <img
                      className="w-[60px] h-[30px]"
                      src={`https://abtrip.vn/images/airlines/${chooseFlight[1].ListFlight[0].Airline.toLowerCase()}.gif`}
                      alt=""
                    />
                    <div className="flex flex-col gap-1">
                      <div className="font-bold text-[#27272A]">
                        {
                          airlineInfo.find(
                            (air) => air.code === chooseFlight[1].Airline
                          ).name_en
                        }
                      </div>
                      <div className="text-[#808089]">
                        {chooseFlight[1].ListFlight[0].FlightNumber}
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{ borderTopWidth: "0.5px", borderColor: "#ebebf0" }}
                    className="my-3"
                  />
                  <div className="flex w-full justify-between items-center">
                    <div className="flex flex-col">
                      <div className="font-bold">
                        {chooseFlight[1].ListFlight[0].StartPoint}
                      </div>
                      <div className="text-[12px]">
                        {moment(chooseFlight[1].ListFlight[0].StartDate).format(
                          "HH:mm"
                        )}
                      </div>
                      <div className="text-[12px]">
                        {moment(chooseFlight[1].ListFlight[0].StartDate).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                    <div className="relative">
                      <div className="text-[12px] w-full text-center text-[#808089] absolute left-1/2 transform -translate-x-1/2 bottom-[12px]">
                        {calculateTimeDifference(
                          chooseFlight[1].ListFlight[0].StartDate,
                          chooseFlight[1].ListFlight[0].EndDate
                        )}
                      </div>
                      <img
                        src={IconFlights}
                        alt=""
                        className="absolute bottom-[-15px] left-1/2 transform -translate-x-1/2"
                      />
                      <img src={IconArrrow} alt="" />
                    </div>
                    <div className="flex flex-col">
                      <div className="font-bold">
                        {chooseFlight[1].ListFlight[0].EndPoint}
                      </div>
                      <div className="text-[12px]">
                        {moment(chooseFlight[1].ListFlight[0].EndDate).format(
                          "HH:mm"
                        )}
                      </div>
                      <div className="text-[12px]">
                        {moment(chooseFlight[1].ListFlight[0].EndDate).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {chooseFlight[1].ListFlight[0].StopNum > 0 &&
                  chooseFlight[1].ListFlight[0].ListSegment.map((s) => (
                    <>
                      {s.StopTime > 0 && (
                        <div
                          key={s.Id}
                          style={{ border: "1px solid #DDDDE3" }}
                          className="flex gap-2 p-2 rounded-[4px] my-4 items-center"
                        >
                          <img src={IconNoti} alt="" />
                          <span>
                            Dừng để đổi chuyến bay tại{" "}
                            {
                              airports.find((air) => air.code === s.EndPoint)
                                ?.city_name_vi
                            }{" "}
                            ({s.StopTime}
                            )m
                          </span>
                        </div>
                      )}
                    </>
                  ))}
              </>
            )}
            <hr className="my-4" style={{ borderTopWidth: "2px" }} />
            <ul className="list-none py-4">
              {chooseFlight[0].Adt > 0 && (
                <li className="flex justify-between mb-2">
                  <span>{chooseFlight[0].Adt} người lớn</span>
                  <span>
                    {bookingInfo.totalListPrice?.totalFareAdt?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    ₫
                  </span>
                </li>
              )}
              {chooseFlight[0].Chd > 0 && (
                <li className="flex justify-between mb-2">
                  <span>{chooseFlight[0].Chd} trẻ em</span>
                  <span>
                    {bookingInfo.totalListPrice?.totalFareChd?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    ₫
                  </span>
                </li>
              )}
              {chooseFlight[0].Inf > 0 && (
                <li className="flex justify-between mb-2">
                  <span>{chooseFlight[0].Inf} em bé</span>
                  <span>
                    {bookingInfo.totalListPrice?.totalFareInf?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    ₫
                  </span>
                </li>
              )}
              {bookingInfo.totalListPrice.totalTax > 0 && (
                <li className="flex justify-between mb-2">
                  <span>Thuế</span>
                  <span>
                    {bookingInfo.totalListPrice?.totalTax?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    ₫
                  </span>
                </li>
              )}
              {baggage > 0 && (
                <li className="flex justify-between mb-2">
                  <span>Hành lý</span>
                  <span>{baggage?.toLocaleString("vi-VN")} ₫</span>
                </li>
              )}
              {bookingInfo.totalListPrice.totalFee > 0 && (
                <li className="flex justify-between mb-2">
                  <span>Phí</span>
                  <span>
                    {bookingInfo.totalListPrice.totalFee?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    ₫
                  </span>
                </li>
              )}
              {bookingInfo.voucherValue > 0 && (
                <li className="flex justify-between mb-2">
                  <span>Giảm giá</span>
                  <span className="text-[#00AB56]">
                    -{bookingInfo?.voucherValue?.toLocaleString("vi-VN")} ₫
                  </span>
                </li>
              )}
              <li className="flex justify-between">
                <span className="font-bold">
                  Tổng số tiền (đã bao gồm chi phí)
                </span>
                <span className="font-bold text-[#01AD53]">
                  {bookingInfo.totalListPrice?.totalDisplay?.toLocaleString(
                    "vi-VN"
                  )}{" "}
                  ₫
                </span>
              </li>
            </ul>
          </div>
          <Button
            onClick={handlePayment}
            variant="filled"
            color="#01AD53"
            className="w-[208px] h-[45px] rounded-[10px] text-center mx-auto bg-[#01AD53] text-white text-[15px]"
          >
            <span className="font-semibold">Thanh toán</span>
          </Button>
        </div>
        {/* <div className="flex bg-white h-[81px] w-full px-[24px] py-3 fixed bottom-0 justify-between items-center">
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
            <a href="/ve-may-bay" className="text-[#22313F] text-[14px] font-medium">
              Vé máy bay
            </a>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Khách sạn</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé tàu</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé xe</span>
          </div>
        </div> */}
      </div>
      {contextHolder}
    </div>
  );
};

export default Step5;
