import { CloseOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Drawer, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  formatNumberWithDots,
  calculateTimeDifference,
} from "../../helpers/utils";
import TicketDetailsDrawer from "../Common/DetailTicket";
import moment from "moment";
import {
  dateFilterAir,
  formatDateNoTime,
  formatTime,
} from "../../helpers/formatDate";
import LoadingAir from "../LoadingAir/LoadingAir";
import _ from "lodash";
import Waiting from "../Common/Waiting";
import BackIcon from "../../images/icon-back.svg";

const plainOptions = [
  {
    label: "VietNam Airline",
    value: "VN",
    image: "https://abtrip.vn/images/airlines/vn.gif",
  },
  {
    label: "Bamboo Airway",
    value: "QH",
    image: "https://abtrip.vn/images/airlines/qh.gif",
  },
  {
    label: "Vietravel",
    value: "VU",
    image: "https://abtrip.vn/images/airlines/vu.gif",
  },
  {
    label: "Vietjet Air",
    value: "VJ",
    image: "https://abtrip.vn/images/airlines/vj.gif",
  },
];

const plainChairOptions = [
  {
    label: "Phổ thông",
    value: 1,
  },
  {
    label: "Phổ thông đặc biệt",
    value: 2,
  },
  {
    label: "Thương gia",
    value: 3,
  },
];

const timeOptions = [
  {
    label: "Trước 06:00",
    value: 1,
  },
  {
    label: "06:00 - 12:00",
    value: 2,
  },
  {
    label: "12:00 - 18:00",
    value: 3,
  },
  {
    label: "Sau 18:00",
    value: 4,
  },
];

const defaultCheckedList = ["VN", "QH", "VU", "VJ"];
const defaultChairCheckedList = [1, 2, 3];

const Step2 = ({
  onChangeStep,
  listFilght,
  setChooseFlight,
  chooseFlight,
  tripType,
  airport,
  airports,
  setStep,
  filterDay,
  setFilterDay,
  filterDayReturn,
  setFilterDayReturn,
  loadingSearchFlight,
  onBackButton,
  ChoosingStep,
  setChoosingStep,
}) => {
  const [openFilter, setOpenFilter] = useState(false);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [filterTime, setFilterTime] = useState("");
  const [checkedListChair, setCheckedListChair] = useState(
    defaultChairCheckedList
  );

  const [typeSort, setTypeSort] = useState("price");
  const [sortIncrease, setSortIncrease] = useState(true);
  const [sortedFlights, setSortedFlights] = useState([]);
  const [filteredFlights, setFilteredFlights] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [detailTicket, setDetailTicket] = useState({});
  const [visibleFlightClass, setVisibleFlightClass] = useState({});
  const [groupedFlights, setGroupedFlights] = useState([]);
  const [filterDayOriginal] = useState(filterDay);
  const [activeDay, setActiveDay] = useState(filterDay);
  const [startDayCalender, setStartDayCalender] = useState(filterDay);
  const [rangeOriginal, setRangeOriginal] = useState(0);
  const [rangCalender, setRangCalender] = useState(0);
  const airVn = {
    VJ: "VietJet Air",
    VN: "Vietnam Airlines",
    QH: "Bamboo Airways",
    VU: "Vietravel",
  };

  useEffect(() => {
    if (filterDayReturn) {
      const daysDifference = moment(filterDayReturn, "DDMMYYYY").diff(
        moment(filterDay, "DDMMYYYY"),
        "days"
      );
      setRangCalender(Math.max(daysDifference, 5));
      setRangeOriginal(Math.max(daysDifference, 5));
    } else {
      setRangCalender(5);
    }
  }, []);

  const toggleFlightClasses = (index) => {
    setVisibleFlightClass((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (list) => {
    setCheckedList((prev) => {
      if (prev.includes(list)) {
        return prev.filter((item) => item !== list);
      }
      return [...prev, list];
    });
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? defaultCheckedList : []);
  };

  const handleFilterTime = (filteredData) => {
    const getTimeRange = (startHour, endHour) => ({
      start: moment().hour(startHour).minute(0).second(0),
      end: endHour ? moment().hour(endHour).minute(0).second(0) : undefined,
    });

    if (filterTime) {
      const data = filteredData.filter((item) => {
        const timeStartAir = moment(item.ListFlight[0].StartDate).set({
          year: moment().year(),
          month: moment().month(),
          date: moment().date(),
        });
        const timeRanges = {
          1: getTimeRange(0, 6),
          2: getTimeRange(6, 12),
          3: getTimeRange(12, 18),
          4: getTimeRange(18),
        };

        const { start, end } = timeRanges[filterTime] || {};
        return end
          ? start <= timeStartAir && end >= timeStartAir
          : start <= timeStartAir;
      });

      return data;
    }

    return filteredData;
  };

  const handleFilter = () => {
    // Bước 1: Lọc dữ liệu theo hãng máy bay
    setOpenFilter(false);

    const filteredData = sortedFlights.filter((item) =>
      checkedList.includes(item.Airline)
    );

    const filteredTime = handleFilterTime(filteredData);

    // Bước 2: Sắp xếp dữ liệu theo giá
    const sortedData = filteredTime.sort((a, b) => {
      if (typeSort === "price") {
        return sortIncrease
          ? a.TotalPrice - b.TotalPrice
          : b.TotalPrice - a.TotalPrice;
      }
      if (typeSort === "timeStart") {
        const startDateA = new Date(a.ListFlight[0].StartDate);
        const startDateB = new Date(b.ListFlight[0].StartDate);
        return sortIncrease ? startDateA - startDateB : startDateB - startDateA;
      }

      return sortIncrease
        ? a.TotalPrice - b.TotalPrice
        : b.TotalPrice - a.TotalPrice;
    });

    return setFilteredFlights(sortedData);
  };

  useEffect(() => {
    handleFilter();
  }, [sortIncrease, sortedFlights, typeSort]);

  useEffect(() => {
    if (tripType === "oneWay") {
      if (ChoosingStep === 1) {
        setSortedFlights(listFilght);
      } else {
        setSortedFlights([]);
      }
    } else {
      if (ChoosingStep === 1) {
        setSortedFlights(
          listFilght.filter(
            (item) => item.ListFlight?.[0]?.StartPoint === airport[0]
          )
        );
      } else if (ChoosingStep === 2) {
        setSortedFlights(
          listFilght.filter(
            (item) => item.ListFlight?.[0]?.StartPoint === airport[1]
          )
        );
      } else {
        setSortedFlights([]);
      }
    }
  }, [ChoosingStep, listFilght, airport, tripType]);

  function groupFlightsByClass(fareDataList) {
    const grouped = fareDataList.reduce((acc, currentFareData) => {
      const flightInfo = currentFareData.ListFlight[0];

      // Tạo key để xác định duy nhất một chuyến bay dựa vào StartDate và Airline
      const key = `${flightInfo.StartDate}-${flightInfo.Airline}`;

      if (!acc[key]) {
        // Nếu chuyến bay chưa tồn tại trong kết quả nhóm, thêm nó với các hạng ghế hiện tại
        acc[key] = {
          startDate: flightInfo.StartDate,
          airline: flightInfo.Airline,
          flights: [], // Chứa thông tin các hạng ghế cho cùng một chuyến bay
        };
      }

      // Thêm hạng ghế vào chuyến bay đã được nhóm
      acc[key].flights.push(currentFareData);

      return acc;
    }, {});

    // Trả về kết quả dưới dạng mảng
    return Object.values(grouped);
  }

  // Gọi hàm và in kết quả
  useEffect(() => {
    setGroupedFlights(groupFlightsByClass(filteredFlights));
  }, [filteredFlights]);

  const selectFilterDay = (currentDate) => {
    if (ChoosingStep === 3) return;
    if (ChoosingStep === 1) {
      setFilterDay(dateFilterAir(currentDate));
    } else {
      setFilterDayReturn(dateFilterAir(currentDate));
    }
  };

  const renderDay = (index) => {
    const indexFocus = index;
    const dateNames = [
      "Chủ nhật",
      "Thứ 2",
      "Thứ 3",
      "Thứ 4",
      "Thứ 5",
      "Thứ 6",
      "Thứ 7",
    ];
    const currentDate = moment(startDayCalender, "DDMMYYYY").add(
      Math.abs(indexFocus),
      "days"
    );
    const day = currentDate.day();
    const color = day === 0 ? "#D72F20" : day === 6 ? "#01AD53" : "#0B2727";

    return (
      <div
        key={index}
        className={`flex flex-col rounded p-2 gap-2 justify-center items-center text-[${color}] ${
          activeDay === dateFilterAir(currentDate) ? "calender-active" : ""
        }`}
        onClick={() => {
          setActiveDay(dateFilterAir(currentDate));
          selectFilterDay(currentDate);
        }}
      >
        <div className="text-[14px] font-bold whitespace-nowrap">
          {moment(currentDate).isSame(moment(), "day")
            ? "Hôm nay"
            : dateNames[day]}
        </div>
        <div className="text-[14px] font-bold">
          {currentDate.format("DD/MM")}
        </div>
      </div>
    );
  };

  const calender = useMemo(() => {
    return Array(rangCalender + 1)
      .fill("")
      .map((_, index) => renderDay(index));
  }, [rangCalender, activeDay, startDayCalender]);

  const handleSort = (type) => {
    setTypeSort(type);
    setSortIncrease((prev) => (type === typeSort ? !prev : true));
  };

  const handleSelectTicket = (item) => {
    if (tripType === "oneWay") {
      if (ChoosingStep === 1) {
        setChooseFlight([item]);
        setChoosingStep(3);
      } else {
        onChangeStep(3);
      }
    } else {
      if (ChoosingStep === 1) {
        setChooseFlight([item]);
        setChoosingStep(2);
      } else if (ChoosingStep === 2) {
        setChooseFlight([...chooseFlight, item]);
        setChoosingStep(3);
      } else {
        onChangeStep(3);
      }
      setActiveDay(filterDayReturn);
      setStartDayCalender(filterDayReturn);
      setRangCalender(30);
    }
    setDrawerVisible(false);
  };

  return (
    <div className="bg-step1 min-h-screen relative flex flex-col step2 overflow-hidden">
      <section className="step-indicator !block !p-0">
        <div className="flex items-end pr-[14px] pt-[9px]">
          <Button
            className="h-[60px] !w-[50px]"
            ghost
            icon={<img src={BackIcon} alt="" />}
            onClick={() => {
              setChooseFlight([]);
              setFilterDay("");
              setStep((prev) => prev - 1);
              setChoosingStep(1);
            }}
          ></Button>
          <div className="flex gap-[12.5px] w-11/12 overflow-auto calender-air">
            {calender}
          </div>
        </div>
        <Row className="justify-between items-center h-14 px-4">
          <Col>
            <Button
              className="rounded-[20px] text-sm px-6"
              icon={<img src="/images/filter.svg" alt="" />}
              onClick={() => setOpenFilter(true)}
            >
              Lọc
            </Button>
          </Col>
          <Col>
            <Button
              className="rounded-[20px] text-sm px-6"
              onClick={() => handleSort("timeStart")}
            >
              Giờ đi
              {typeSort === "timeStart" && (
                <div className={`${sortIncrease ? "" : "rotate-180 pt-1"}`}>
                  <img className="h-[14px]" src="/images/arrow-up.svg" alt="" />
                </div>
              )}
            </Button>
          </Col>
          <Col>
            <Button
              className="rounded-[20px] text-sm px-6 w-[80px]"
              onClick={() => handleSort("price")}
            >
              Giá
              {typeSort === "price" && (
                <div className={`${sortIncrease ? "" : "rotate-180 pt-1"}`}>
                  <img className="h-[14px]" src="/images/arrow-up.svg" alt="" />
                </div>
              )}
            </Button>
          </Col>
        </Row>
      </section>
      {loadingSearchFlight ? (
        <Waiting
          airport={airport}
          time={
            tripType === "oneWay" ? [filterDay] : [filterDay, filterDayReturn]
          }
        />
      ) : (
        <>
          {sortedFlights.length === 0 && ChoosingStep === 1 && (
            <>
              <div className="flex w-full absolute top-[135px] left-1/2 transform -translate-x-1/2 justify-center items-center gap-8">
                <div className="flex flex-col gap-1">
                  <div className="text-white font-semibold text-[18px]">
                    {airport?.[0]}
                  </div>
                </div>
                <div className="relative">
                  <img src={"/images/ic-flights.svg"} alt="" />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="text-white font-semibold text-[18px]">
                    {" "}
                    {airport?.[1]}
                  </div>
                </div>
              </div>
              <h2 className="w-full text-white font-semibold text-[18px] text-center absolute top-[380px] left-1/2 transform -translate-x-1/2 p-4">
                Không tìm thấy chuyển bay phù hợp, vui lòng tìm kiếm lại
              </h2>
            </>
          )}
          <div
            className={`flex-1 flex flex-col items-center px-4 py-2
             max-h-[calc(100vh-125px)] ${ChoosingStep === 3 ? "max-h-[calc(100vh-242px)] overflow-auto" : "max-h-[calc(100vh-120px)]"}`}
          >
            {ChoosingStep === 1 && sortedFlights.length && (
              <div className="w-full mb-2">
                <div className="h-8 text-white font-bold text-[12px] flex items-center">
                  Vé chiều đi
                </div>
              </div>
            )}
            {ChoosingStep === 2 && (
              <div className="w-full flex flex-col gap-2 mb-2">
                <div className="flex justify-between items-center">
                  <b className="text-white font-bold text-[12px]">
                    Vé chiều đi
                  </b>
                  <Button
                    onClick={() => {
                      setChoosingStep(1);
                      setChooseFlight([]);
                      setActiveDay(filterDay);
                      setStartDayCalender(filterDayOriginal);
                      setRangCalender(rangeOriginal);
                    }}
                    className="rounded-[20px] text-center  bg-white text-[#01AD53] border-[#01AD53] text-[10px]"
                  >
                    Thay đổi
                  </Button>
                </div>
                {chooseFlight.map((item, index) => (
                  <div
                    key={index}
                    className=" w-full px-2 py-2 bg-white rounded-[20px]"
                  >
                    <Row
                      gutter={2}
                      className="flex-nowrap items-center justify-between"
                    >
                      <Col>
                        <div>
                          <div className="flex flex-col gap-2">
                            <img
                              className="w-auto h-4"
                              src={`https://abtrip.vn/images/airlines/${item.Airline.toLowerCase()}.gif`}
                              alt=""
                            />
                            <p className="text-[12px] text-primary text-center">
                              {item?.ListFlight?.[0]?.FlightNumber || ""}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <Row gutter={4} align={"middle"}>
                          <Col>
                            <div className="flex flex-col gap-1">
                              <h4 className="font-bold text-[12px] text-center">
                                {formatTime(item?.ListFlight?.[0]?.StartDate)}
                              </h4>
                              <p className="text-[10px] text-center">
                                {item?.ListFlight?.[0].StartPoint}
                              </p>
                              <h4 className="font-bold text-[8px] text-center">
                                {formatDateNoTime(
                                  item?.ListFlight?.[0]?.StartDate
                                )}
                              </h4>
                            </div>
                          </Col>
                          <Col className="justify-center text-center">
                            <p className="text-[10px] text-[#808089]">
                              {calculateTimeDifference(
                                item?.ListFlight?.[0]?.StartDate,
                                item?.ListFlight?.[item.ListFlight.length - 1]
                                  ?.EndDate
                              )}
                            </p>
                            <img
                              className="w-14"
                              src="/images/flight-line.svg"
                              alt=""
                            />

                            <p className="text-[10px] text-[#808089]">
                              {(item.ListFlight || []).length === 1
                                ? "Bay thẳng"
                                : `${(item.ListFlight || []).length - 1} điểm dừng`}
                            </p>
                          </Col>
                          <Col>
                            <div className="flex flex-col gap-1">
                              <h4 className="font-bold text-[12px] text-center">
                                {formatTime(item?.ListFlight?.[0]?.EndDate)}
                              </h4>
                              <p className="text-[10px] text-center">
                                {item?.ListFlight?.[0].EndPoint}
                              </p>
                              <h4 className="font-bold text-[8px] text-center">
                                {formatDateNoTime(
                                  item?.ListFlight?.[0]?.EndDate
                                )}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <div className="font-bold text-primary text-[14px] text-center">
                          {formatNumberWithDots(item.TotalPrice)}{" "}
                          {item.Currency}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}

                <b className="text-white font-bold text-[12px] text-center">
                  {" "}
                  Vé chiều về
                  <DoubleRightOutlined className="rotate-90 ml-2" />
                </b>
              </div>
            )}
            {ChoosingStep === 3 && (
              <div className="w-full flex flex-col gap-2 mb-2">
                <div className="flex justify-between items-center">
                  <b className="text-white font-bold text-[12px]">
                    Vé chiều đi
                  </b>
                  <Button
                    onClick={() => {
                      setChoosingStep(1);
                      setChooseFlight([]);
                      setActiveDay(filterDay);
                      setStartDayCalender(filterDayOriginal);
                    }}
                    className="rounded-[20px] text-center  bg-white text-[#01AD53] border-[#01AD53] text-[10px]"
                  >
                    Thay đổi
                  </Button>
                </div>
                {chooseFlight.slice(0, 1).map((item, index) => (
                  <div
                    key={index}
                    className="w-full px-2 py-2 bg-white rounded-[20px]"
                  >
                    <Row
                      gutter={8}
                      className="flex-nowrap items-center justify-between"
                    >
                      <Col>
                        <div>
                          <div className="flex flex-col gap-2">
                            <img
                              className="h-4"
                              src={`https://abtrip.vn/images/airlines/${item.Airline.toLowerCase()}.gif`}
                              alt=""
                            />
                            <p className="text-[12px] text-primary text-center">
                              {item?.ListFlight?.[0]?.FlightNumber || ""}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <Row gutter={4} align={"middle"}>
                          <Col>
                            <div className="flex flex-col gap-1">
                              <h4 className="font-bold text-[12px] text-center">
                                {formatTime(item?.ListFlight?.[0]?.StartDate)}
                              </h4>
                              <p className="text-[10px] text-center">
                                {item?.ListFlight?.[0].StartPoint}
                              </p>
                              <h4 className="font-bold text-[8px] text-center">
                                {formatDateNoTime(
                                  item?.ListFlight?.[0]?.StartDate
                                )}
                              </h4>
                            </div>
                          </Col>
                          <Col className="justify-center text-center">
                            <p className="text-[10px] text-[#808089]">
                              {calculateTimeDifference(
                                item?.ListFlight?.[0]?.StartDate,
                                item?.ListFlight?.[item.ListFlight.length - 1]
                                  ?.EndDate
                              )}
                            </p>
                            <img
                              className="w-14"
                              src="/images/flight-line.svg"
                              alt=""
                            />

                            <p className="text-[10px] text-[#808089]">
                              {(item.ListFlight || []).length === 1
                                ? "Bay thẳng"
                                : `${(item.ListFlight || []).length - 1} điểm dừng`}
                            </p>
                          </Col>
                          <Col>
                            <div className="flex flex-col gap-1">
                              <h4 className="font-bold text-[12px] text-center">
                                {formatTime(item?.ListFlight?.[0]?.EndDate)}
                              </h4>
                              <p className="text-[10px] text-center">
                                {item?.ListFlight?.[0].EndPoint}
                              </p>
                              <h4 className="font-bold text-[8px] text-center">
                                {formatDateNoTime(
                                  item?.ListFlight?.[0]?.EndDate
                                )}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <div className="font-bold text-primary text-[14px] text-center">
                          {formatNumberWithDots(item.TotalPrice)}{" "}
                          {item.Currency}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
                {tripType !== "oneWay" && (
                  <>
                    <div className="flex justify-between items-center">
                      <b className="text-white font-bold text-[12px]">
                        Vé chiều về
                      </b>
                      <Button
                        onClick={() => {
                          setChoosingStep(2);
                          setChooseFlight((prev) => [prev[0]]);
                        }}
                        className="rounded-[20px] text-center  bg-white text-[#01AD53] border-[#01AD53] text-[10px]"
                      >
                        Thay đổi
                      </Button>
                    </div>
                    {chooseFlight.slice(1, 2).map((item, index) => (
                      <div
                        key={index}
                        className="w-full px-2 py-2 bg-white rounded-[20px] mb-4 "
                      >
                        <Row
                          gutter={8}
                          className="flex-nowrap items-center justify-between"
                        >
                          <Col>
                            <div className="flex flex-col gap-2">
                              <img
                                className="w-auto h-4"
                                src={`https://abtrip.vn/images/airlines/${item.Airline.toLowerCase()}.gif`}
                                alt=""
                              />
                              <p className="text-[12px] text-primary text-center">
                                {item?.ListFlight?.[0]?.FlightNumber || ""}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <Row align={"middle"}>
                              <Col>
                                <div className="flex flex-col gap-1">
                                  <h4 className="font-bold text-[12px] text-center">
                                    {formatTime(
                                      item?.ListFlight?.[0]?.StartDate
                                    )}
                                  </h4>
                                  <p className="text-[10px] text-center">
                                    {item?.ListFlight?.[0].StartPoint}
                                  </p>
                                  <h4 className="font-bold text-[8px] text-center">
                                    {formatDateNoTime(
                                      item?.ListFlight?.[0]?.StartDate
                                    )}
                                  </h4>
                                </div>
                              </Col>
                              <Col className="justify-center text-center">
                                <p className="text-[10px] text-[#808089]">
                                  {calculateTimeDifference(
                                    item?.ListFlight?.[0]?.StartDate,
                                    item?.ListFlight?.[
                                      item.ListFlight.length - 1
                                    ]?.EndDate
                                  )}
                                </p>
                                <img
                                  className="w-14"
                                  src="/images/flight-line.svg"
                                  alt=""
                                />

                                <p className="text-[10px] text-[#808089]">
                                  {(item.ListFlight || []).length === 1
                                    ? "Bay thẳng"
                                    : `${
                                        (item.ListFlight || []).length - 1
                                      } điểm dừng`}
                                </p>
                              </Col>
                              <Col>
                                <div className="flex flex-col gap-1">
                                  <h4 className="font-bold text-[12px] text-center">
                                    {formatTime(item?.ListFlight?.[0]?.EndDate)}
                                  </h4>
                                  <p className="text-[10px] text-center">
                                    {
                                      item?.ListFlight?.[
                                        item.ListFlight.length - 1
                                      ].EndPoint
                                    }
                                  </p>
                                  <h4 className="font-bold text-[8px] text-center">
                                    {formatDateNoTime(
                                      item?.ListFlight?.[0]?.EndDate
                                    )}
                                  </h4>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <div className="font-bold text-primary text-[14px] text-center">
                              {formatNumberWithDots(item.TotalPrice)}{" "}
                              {item.Currency}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
            {loadingSearchFlight ? (
              <div>
                <LoadingAir />
                <div className="text-white flex flex-col mt-10 w-3/4 mx-auto items-center">
                  <div>Vui lòng đợi trong giây lát</div>
                  <div>hệ thống đang tìm kiếm chuyến bay ...</div>
                </div>
              </div>
            ) : (
              <div className="max-h-full overflow-auto list-air-flight w-full">
                {groupedFlights.map((airline, index) =>
                  airline.flights.map((item, flightIndex) => {
                    if (flightIndex === 0)
                      return (
                        <div
                          key={flightIndex}
                          className="w-full px-2 py-2 bg-white rounded-[20px] mb-4"
                        >
                          <Row
                            className="justify-between items-center"
                            onClick={() => {
                              setDetailTicket(item);
                              setDrawerVisible(true);
                            }}
                          >
                            <Col>
                              <Row gutter={4} align={"middle"}>
                                <Col className="flex flex-col gap-1">
                                  <h4 className="font-bold text-[12px] text-center">
                                    {formatTime(
                                      item?.ListFlight?.[0]?.StartDate
                                    )}
                                  </h4>
                                  <p className="text-[10px] text-center">
                                    {item?.ListFlight?.[0].StartPoint}
                                  </p>
                                  <h4 className="font-bold text-[8px] text-center">
                                    {formatDateNoTime(
                                      item?.ListFlight?.[0]?.StartDate
                                    )}
                                  </h4>
                                </Col>
                                <Col className="justify-center text-center">
                                  <p className="text-[10px] text-[#808089]">
                                    {calculateTimeDifference(
                                      item?.ListFlight?.[0]?.StartDate,
                                      item?.ListFlight?.[
                                        item.ListFlight.length - 1
                                      ]?.EndDate
                                    )}
                                  </p>
                                  <img src="/images/flight-line.svg" alt="" />

                                  <p className="text-[10px] text-[#808089]">
                                    {(item.ListFlight || []).length === 1
                                      ? "Bay thẳng"
                                      : `${
                                          (item.ListFlight || []).length - 1
                                        } điểm dừng`}
                                  </p>
                                </Col>
                                <Col className="flex flex-col gap-1">
                                  <h4 className="font-bold text-[12px] text-center">
                                    {formatTime(item?.ListFlight?.[0]?.EndDate)}
                                  </h4>
                                  <p className="text-[10px] text-center">
                                    {
                                      item?.ListFlight?.[
                                        item.ListFlight.length - 1
                                      ].EndPoint
                                    }
                                  </p>
                                  <h4 className="font-bold text-[8px] text-center">
                                    {formatDateNoTime(
                                      item?.ListFlight?.[0]?.EndDate
                                    )}
                                  </h4>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <div className="flex gap-2 items-center mb-2">
                                <div className="font-bold text-primary text-[14px] ">
                                  {formatNumberWithDots(item.TotalPrice)}{" "}
                                  {item.Currency}
                                </div>
                                <Button
                                  className="rounded-[16px] h-6 text-[10px] p-1 px-2 bg-[#36C643]"
                                  variant="filled"
                                  color="#01AD53"
                                  type="primary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSelectTicket(item);
                                  }}
                                >
                                  <p className="text-white text-[12px]">
                                    Chọn vé
                                  </p>
                                </Button>
                              </div>

                              <div className="flex gap-2 items-center justify-between">
                                <p className="text-[10px] text-[#808089]">
                                  /
                                  {_.sum([
                                    item["Adt"],
                                    item["Chd"],
                                    item["Inf"],
                                  ])}{" "}
                                  Hành khách
                                </p>
                                <p className="text-primary text-[14px] flex gap-1 items-center cursor-pointer">
                                  Chi tiết{" "}
                                  <img src="/images/2-down.svg" alt="" />
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <hr className="my-2" />
                          <Row className="justify-between items-center">
                            <div>
                              <div className="flex gap-2 items-center">
                                <img
                                  className="w-auto h-4"
                                  src={`https://abtrip.vn/images/airlines/${item.Airline.toLowerCase()}.gif`}
                                  alt=""
                                />
                                <div className="flex flex-col gap-1">
                                  <p className="text-[10px]">
                                    {airVn[item.Airline] || item.Airline || ""}
                                  </p>
                                  <p className="text-[12px] text-primary">
                                    {item?.ListFlight?.[0]?.FlightNumber || ""}/{" "}
                                    {item?.ListFlight?.[0]?.GroupClass}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {airline.flights.length > 1 && (
                              <Button
                                className="rounded-[20px]  py-0 px-2 w-fit h-6 text-[10px] hover:bg-[#36C643] focus:bg-[#36C643]"
                                style={{ border: "1px solid #36C643" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFlightClasses(index);
                                }}
                              >
                                <p className="text-primary text-[12px] flex gap-1 items-center cursor-pointer">
                                  {airline.flights.length - 1} hạng vé khác{" "}
                                  <img src="/images/2-down.svg" alt="" />
                                </p>
                              </Button>
                            )}
                          </Row>

                          {/* Hiển thị các hạng vé khác khi được yêu cầu */}
                          {visibleFlightClass[index] &&
                            airline.flights
                              .slice(1)
                              .map((extraClass, extraIndex) => (
                                <div
                                  key={`${index}-${extraIndex}`}
                                  className="w-full mt-2 px-2 py-2 bg-white border-[1px] border-[#01AD53] border-solid rounded-[20px]"
                                  onClick={() => {
                                    setDetailTicket(extraClass);
                                    setDrawerVisible(true);
                                  }}
                                >
                                  <Row className="justify-between items-center">
                                    <Col>
                                      <Row gutter={4} align={"middle"}>
                                        <Col>
                                          <h4 className="font-bold text-[12px]">
                                            {extraClass?.ListFlight?.[0]?.StartDt.split(
                                              " "
                                            )[1].split(":")[0] +
                                              ":" +
                                              extraClass?.ListFlight?.[0]?.StartDt.split(
                                                " "
                                              )[1].split(":")[1]}
                                          </h4>
                                          <p className="text-[10px]">
                                            {
                                              extraClass?.ListFlight?.[0]
                                                .StartPoint
                                            }
                                          </p>
                                        </Col>
                                        <Col className="justify-center text-center">
                                          <p className="text-[10px] text-[#808089]">
                                            {calculateTimeDifference(
                                              extraClass?.ListFlight?.[0]
                                                ?.StartDate,
                                              extraClass?.ListFlight?.[
                                                extraClass.ListFlight.length - 1
                                              ]?.EndDate
                                            )}
                                          </p>
                                          <img
                                            src="/images/flight-line.svg"
                                            alt=""
                                          />

                                          <p className="text-[10px] text-[#808089]">
                                            {(extraClass.ListFlight || [])
                                              .length === 1
                                              ? "Bay thẳng"
                                              : `${
                                                  (extraClass.ListFlight || [])
                                                    .length - 1
                                                } điểm dừng`}
                                          </p>
                                        </Col>
                                        <Col>
                                          <h4 className="font-bold text-[12px]">
                                            {extraClass?.ListFlight?.[
                                              extraClass.ListFlight.length - 1
                                            ]?.EndDt.split(" ")[1].split(
                                              ":"
                                            )[0] +
                                              ":" +
                                              extraClass?.ListFlight?.[
                                                extraClass.ListFlight.length - 1
                                              ]?.EndDt.split(" ")[1].split(
                                                ":"
                                              )[1]}
                                          </h4>
                                          <p className="text-[10px]">
                                            {
                                              extraClass?.ListFlight?.[
                                                extraClass.ListFlight.length - 1
                                              ].EndPoint
                                            }
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col>
                                      <div className="flex gap-2 items-center mb-2">
                                        <div>
                                          <div className="text-primary text-[14px] font-semibold mt-1">
                                            {formatNumberWithDots(
                                              extraClass.TotalPrice
                                            )}{" "}
                                            {extraClass.Currency}
                                          </div>
                                        </div>
                                        <Button
                                          className="rounded-[16px] h-6 text-[10px]  p-1 px-2 bg-[#01AD53]"
                                          variant="filled"
                                          color="#01AD53"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleSelectTicket(extraClass);
                                          }}
                                        >
                                          <p className="text-white text-[12px]">
                                            Chọn vé
                                          </p>
                                        </Button>
                                      </div>

                                      <div className="flex gap-2 items-center justify-between">
                                        <p className="text-[10px] text-[#808089]">
                                          /
                                          {_.sum([
                                            extraClass["Adt"],
                                            extraClass["Chd"],
                                            extraClass["Inf"],
                                          ])}{" "}
                                          Hành khách
                                        </p>
                                        <p className="text-primary text-[14px] flex gap-1 items-center cursor-pointer mt-1">
                                          Chi tiết
                                          <img
                                            src="/images/2-down.svg"
                                            alt=""
                                          />
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <div>
                                      <div className="flex gap-2 items-center">
                                        <img
                                          className="w-auto h-4"
                                          src={`https://abtrip.vn/images/airlines/${extraClass.Airline.toLowerCase()}.gif`}
                                          alt=""
                                        />
                                        <div className="flex flex-col gap-1">
                                          <p className="text-[10px]">
                                            {airVn[extraClass.Airline] ||
                                              extraClass.Airline ||
                                              ""}
                                          </p>
                                          <p className="text-[12px] text-primary">
                                            {extraClass?.ListFlight?.[0]
                                              ?.FlightNumber || ""}
                                            /{" "}
                                            {
                                              extraClass?.ListFlight?.[0]
                                                ?.GroupClass
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              ))}
                        </div>
                      );
                  })
                )}
              </div>
            )}
          </div>
          {ChoosingStep === 3 ? (
            <div className="sticky bottom-0 right-0 w-full">
              <div className="bg-white  shadow p-4">
                <Row justify={"space-between"}>
                  <Col className="font-bold text-[#808089] text-md">
                    Tổng tiền
                  </Col>
                  <Col className="font-bold text-primary text-md">
                    {formatNumberWithDots(
                      chooseFlight.reduce((a, b) => a + b.TotalPrice, 0)
                    )}{" "}
                    {chooseFlight?.[0]?.Currency}
                  </Col>
                </Row>
                <Button
                  className="w-full mt-4 h-10 font-bold  bg-[#01AD53]"
                  onClick={() => onChangeStep(3)}
                  variant="filled"
                  color="#01AD53"
                >
                  <span className="font-bold text-white"> Tiếp Tục</span>
                </Button>
              </div>
            </div>
          ) : sortedFlights.length === 0 && ChoosingStep === 1 ? (
            <div className="sticky bottom-10 right-0 w-full shadow text-center">
              <Button
                onClick={onBackButton}
                className="w-[208px] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#01AD53] text-[15px]"
              >
                <span className="font-semibold">Quay lại</span>
              </Button>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <Drawer
        placement={"bottom"}
        closable={false}
        onClose={() => setOpenFilter(false)}
        open={openFilter}
        footer={
          <div>
            <Button
              type="primary"
              onClick={handleFilter}
              className="w-full  bg-[#01AD53] hover:bg-[#01AD53] rounded-lg mt-4 h-12 text-[16px] font-semibold"
            >
              Áp dụng
            </Button>
          </div>
        }
        height="100vh"
      >
        <div className="header-filter">
          <div onClick={() => setOpenFilter(false)}>
            <CloseOutlined />
          </div>
          <div>Bộ lọc</div>
        </div>
        <div className="max-h-[calc(100%-41px)] overflow-y-auto overflow-x-h p-4">
          <div className="card-filter pb-4">
            <div className="card-filter-title">Lọc theo giờ khởi hành</div>
            <Row gutter={[16, 8]} className="flex mt-2 p-2 card-filter-body">
              {timeOptions.map((item) => (
                <Col span={12}>
                  <div
                    className={`border border-dashed border-[#d9d9d9] h-12 flex items-center justify-center ${filterTime === item.value ? "border-2 !border-solid !border-[#3875CA]" : ""}`}
                    onClick={() =>
                      setFilterTime((prev) =>
                        item.value === prev ? "" : item.value
                      )
                    }
                  >
                    {item.label}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="card-filter mt-6">
            <div className="card-filter-title">Lọc theo hãng</div>
            <div className="flex flex-col card-filter-body">
              {plainOptions.map((item) => (
                <Checkbox
                  key={item.value}
                  onChange={() => onChange(item.value)}
                  checked={checkedList.includes(item.value)}
                  className="mb-2"
                >
                  <div className="flex gap-2 items-center">
                    <div className="min-w-[100px]">
                      <img className="h-5" src={item.image} alt="" />
                    </div>
                    <p>{item.label}</p>
                  </div>
                </Checkbox>
              ))}
            </div>
          </div>
          <div className="card-filter mt-6">
            <div className="card-filter-title">Lọc theo hạng ghế</div>
            <div className="flex flex-col card-filter-body">
              {plainChairOptions.map((item) => (
                <Checkbox
                  key={item.value}
                  onChange={() => onChange(item.value)}
                  checked={checkedListChair.includes(item.value)}
                  className="mb-2"
                >
                  <div className="flex gap-2 items-center">
                    <p>{item.label}</p>
                  </div>
                </Checkbox>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
      <TicketDetailsDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        ticketDetails={detailTicket}
        airports={airports}
        tripType={tripType}
        ChoosingStep={ChoosingStep}
        setChooseFlight={setChooseFlight}
        setChoosingStep={setChoosingStep}
        onChangeStep={onChangeStep}
        chooseFlight={chooseFlight}
        handleSelectTicket={handleSelectTicket}
      />
    </div>
  );
};

export default Step2;
