import React from "react";
import { Drawer, Button, Typography } from "antd";
import { Row, Col } from "antd";
import {
  formatNumberWithDots,
  calculateTimeDifference,
} from "../../helpers/utils";
import dayjs from "dayjs";
import { isArray } from "lodash";
const { Text, Title } = Typography;

const TicketDetailsDrawer = ({
  visible,
  onClose,
  ticketDetails,
  airports,
  handleSelectTicket,
}) => {
  return (
    <Drawer placement="bottom" onClose={onClose} open={visible} height={"auto"}>
      <div className="p-4">
        <Title level={4}>
          {isArray(airports) &&
            airports?.find(
              (item) => item.code === ticketDetails.ListFlight?.[0]?.StartPoint
            )?.city_name_vi}{" "}
          -{" "}
          {isArray(airports) &&
            airports?.find(
              (item) => item.code === ticketDetails.ListFlight?.[0]?.EndPoint
            )?.city_name_vi}{" "}
        </Title>
        <div
          className="p-4 rounded-[8px] flex flex-col gap-4"
          style={{
            border: "1px solid #EBEBF0",
          }}
        >
          <div className="flex gap-4 items-center">
            <img
              src={`https://abtrip.vn/images/airlines/${(
                ticketDetails.Airline || ""
              ).toLowerCase()}.gif`}
              alt="logo"
              style={{ width: "auto", height: 30 }}
            />
            <div className="flex flex-col justify-between">
              {" "}
              <Text className="font-bold text-[14px]" strong>
                {ticketDetails.ListFlight?.[0].Airline} -{" "}
                {ticketDetails.ListFlight?.[0].GroupClass}
              </Text>
              <Text className="text-[14px] text-[#808089]">
                {ticketDetails.ListFlight?.[0].FlightNumber}
              </Text>
            </div>
          </div>
          <hr
            style={{
              border: "1px solid #EBEBF0",
            }}
          />
          <Row gutter={16} align={"middle"}>
            <Col span={8}>
              <h4 className="font-bold text-sm">
                {ticketDetails?.ListFlight?.[0].StartPoint}
              </h4>
              <p className="text-xs">
                {ticketDetails?.ListFlight?.[0]?.StartDt.split(" ")[1]}
              </p>
              <p className="text-xs">
                {dayjs(ticketDetails?.ListFlight?.[0]?.StartDate).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </Col>
            <Col span={8} className="justify-center text-center">
              <p className="text-xs text-[#808089]">
                {calculateTimeDifference(
                  ticketDetails?.ListFlight?.[0]?.StartDate,
                  ticketDetails?.ListFlight?.[
                    ticketDetails.ListFlight.length - 1
                  ]?.EndDate
                )}
              </p>
              <img src="/images/flight-line.svg" alt="" />
            </Col>
            <Col span={8}>
              <h4 className="font-bold text-sm">
                {
                  ticketDetails?.ListFlight?.[
                    ticketDetails.ListFlight.length - 1
                  ].EndPoint
                }
              </h4>
              <p className="text-xs">
                {
                  ticketDetails?.ListFlight?.[
                    ticketDetails.ListFlight.length - 1
                  ]?.EndDt.split(" ")[1]
                }
              </p>
              <p className="text-xs">
                {dayjs(
                  ticketDetails?.ListFlight?.[
                    ticketDetails.ListFlight.length - 1
                  ]?.StartDate
                ).format("DD/MM/YYYY")}
              </p>
            </Col>
          </Row>
        </div>

        <div className="flex flex-col gap-2">
          <Row justify="space-between mt-4">
            <Text>{ticketDetails.Adt} Người lớn</Text>
            <Text>
              {formatNumberWithDots(
                ticketDetails.ListFlight?.[0]?.ListPrice.find(
                  (item) => item.PaxType === "ADT"
                )?.Total
              )}{" "}
              {ticketDetails.Currency}
            </Text>
          </Row>
          <Row justify="space-between">
            <Text>{ticketDetails.Chd} Trẻ em</Text>
            <Text>
              {formatNumberWithDots(
                ticketDetails.ListFlight?.[0]?.ListPrice.find(
                  (item) => item.PaxType === "CHD"
                )?.Total
              )}{" "}
              {ticketDetails.Currency}
            </Text>
          </Row>
          <Row justify="space-between ">
            <Text>{ticketDetails.Inf} Em bé</Text>
            <Text>
              {formatNumberWithDots(
                ticketDetails.ListFlight?.[0]?.ListPrice.find(
                  (item) => item.PaxType === "INF"
                )?.Total
              )}{" "}
              {ticketDetails.Currency}
            </Text>
          </Row>
          <Row justify="space-between">
            <Text>Thuế</Text>
            <Text>Đã bao gồm</Text>
          </Row>
          <Row justify="space-between">
            <Text>Hành lý xách tay</Text>
            <Text>
              {ticketDetails?.ListFlight?.[0]?.ListSegment?.[0]?.HandBaggage}
            </Text>
          </Row>
          <Row justify="space-between">
            <Text>Hành lý ký gửi</Text>
            <Text>
              {
                ticketDetails?.ListFlight?.[0]?.ListSegment?.[0]
                  ?.AllowanceBaggage
              }
            </Text>
          </Row>
          <Row justify="space-between">
            <Text>Thủ tục ưu tiên</Text>
            <Text>0 {ticketDetails.Currency}</Text>
          </Row>
          <Row justify="space-between">
            <Text>Giảm giá</Text>
            <Text style={{ color: "green" }}>0 {ticketDetails.Currency}</Text>
          </Row>
          <Row justify="space-between">
            <Text strong>Tổng số tiền</Text>
            <Text strong style={{ fontSize: "18px", color: "#01AD53" }}>
              {formatNumberWithDots(ticketDetails?.TotalPrice)}{" "}
              {ticketDetails.Currency}
            </Text>
          </Row>
        </div>
        <Button
          onClick={() => handleSelectTicket(ticketDetails)}
          type="primary"
          variant="filled"
          color="#01AD53"
          className="w-full h-[48px] mt-6 bg-[#01AD53] hover:bg-[#01AD53] "
        >
          <p className="text-white text-[14px]">Chọn vé</p>
        </Button>
      </div>
    </Drawer>
  );
};

export default TicketDetailsDrawer;
